import Text from '../../atoms/Text';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import LITERALS from '../../../shared/constants/literals';
import { REGEX } from '../../../shared/constants/regex';
import {
	fetchUserSATPolling,
	linkSATAccountHeru,
	postSATLinkFlow,
} from '../../../services/satLinking.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { showAlert, AlertPayload } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../Alert';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import {
	msgToolTipPassword,
	msgTooltip,
} from '../../../shared/constants/SatLinkMessages';

import clevertap from 'clevertap-web-sdk';
import { CaretCircleLeft } from '@phosphor-icons/react';
import { useSATLinkFlow } from '../../../shared/constants/links';
import {
	POLLING_STATUS,
	SATPolling,
	TUserLink,
} from '../../../@types/SATLinking';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';

type SATLinkForm = {
	rfc: string;
	password: string;
};

interface Props {
	onCallbackSuccess?: (value: any) => void;
	onCallbackError?: (error: any) => void;
}

export const schemaSATLinking = yup
	.object()
	.shape({
		rfc: yup
			.string()
			.required(LITERALS.REQUIRED_FIELD)
			.matches(REGEX.rfcRegex, LITERALS.REG_EXP_RFC),
		password: yup.string().required(LITERALS.REQUIRED_FIELD),
		// .matches(REGEX.nameRegex, LITERALS.PASSWORD_INVALID)
		// .min(8, LITERALS.MIN_LENGTH_PASSWORD),
	})
	.required(LITERALS.REQUIRED_FIELD);

const SATLink = ({ onCallbackSuccess, onCallbackError }: Props) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<SATLinkForm>({
		resolver: yupResolver(schemaSATLinking),
		defaultValues: {
			rfc: '',
			password: '',
		},
	});

	const [isLoading, setIsLoading] = useState(false);
	const [numbersOfIntents, setNumbersOfIntents] = useState(0);
	const [userRFC, setUserRFC] = useState('');
	const [pollingStatus, setPollingStatus] = useState(POLLING_STATUS.INACTIVE);

	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const dispatch = useDispatch();
	const { dataLayer } = window as any;
	const logClevertapEvent = useCleverTapEvent();

	const logLinkEvent = (source: 'rfc' | 'Efirma', rfc?: string) => {
		logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
			event_type: EVENT_TYPES.HOME_LINK_ATTEMP,
			id: userProfile?.id!,
			phone: userProfile?.cellphone!,
			source,
		});

		dataLayer.push({
			event: ANALYTICS_EVENTS.ADD_RFC,
			user_id: userProfile?.id,
			phone: userProfile?.cellphone,
			rfc: rfc,
		});
	};

	const successLinkHandler = async (resp: unknown, rfc?: string) => {
		clevertap.profile.push({
			Site: {
				rfc: rfc ? rfc : userRFC,
			},
		});
		showAlertTop({
			title: 'Éxito',
			type: ALERT_TYPE.SUCCESS,
			description: LITERALS.SUCCESS_SAT_LINK,
		});
		setIsLoading(false);
		onCallbackSuccess?.(resp);
	};

	//? POLLING SAT LINK FLOW
	const pullingLinkHandler = async () => {
		try {
			const response: SATPolling = await fetchUserSATPolling(
				userProfile?.id!,
				tokenSession,
			);
			switch (response.code) {
				case POLLING_STATUS.PENDING:
					setPollingStatus(POLLING_STATUS.PENDING);
					setTimeout(() => {
						pullingLinkHandler();
					}, 2000);
					break;

				case POLLING_STATUS.DONE:
					setPollingStatus(POLLING_STATUS.DONE);
					successLinkHandler(response);
					break;

				case POLLING_STATUS.FAIL:
					setPollingStatus(POLLING_STATUS.FAIL);
					setIsLoading(false);
					showAlertTop({
						title: 'Error',
						type: ALERT_TYPE.ERROR,
						description: response.errorDetail,
					});

					break;

				default:
					break;
			}
		} catch (error) {
			// @ts-ignore
			if (error?.response?.data?.msg) {
				// @ts-ignore
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					// @ts-ignore
					description: error.response.data.msg,
				});
			} else {
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					description: LITERALS.FISCAL_LINK_ERROR,
				});
			}
			setIsLoading(false);
			onCallbackError?.(error);
		}
	};

	//? POST SAT LINK FLOW
	const linkSatHandler = async (userData: TUserLink) => {
		try {
			await postSATLinkFlow(userData, tokenSession);
			setPollingStatus(POLLING_STATUS.PENDING);
			setTimeout(() => {
				pullingLinkHandler();
			}, 15000);
		} catch (error) {
			setIsLoading(false);
			setPollingStatus(POLLING_STATUS.FAIL);
		}
	};

	const onSubmit: SubmitHandler<SATLinkForm> = async (data) => {
		if (!userProfile?.id || isLoading) return;
		logLinkEvent('rfc', data.rfc);
		if (numbersOfIntents === 4) {
			setNumbersOfIntents(0);
		}
		try {
			setIsLoading(true);
			const userData = {
				userId: userProfile?.id,
				rfc: data.rfc,
				password: data.password,
				source: LITERALS.PLATFORM,
				fiel: false,
			};

			setUserRFC(data.rfc);
			if (useSATLinkFlow) {
				linkSatHandler(userData);
				return;
			}
			const resp = await linkSATAccountHeru(userData, tokenSession);
			successLinkHandler(resp, data.rfc);
		} catch (error) {
			// @ts-ignore
			if (error?.response?.data?.msg) {
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					// @ts-ignore
					description: error.response.data.msg,
				});
			} else {
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					description: LITERALS.FISCAL_LINK_ERROR,
				});
			}
			setNumbersOfIntents(numbersOfIntents + 1);
			setIsLoading(false);
			onCallbackError?.(error);
		}
	};

	useEffect(() => {
		if (numbersOfIntents === 3) {
			showAlertTop({
				title: 'Alerta',
				type: ALERT_TYPE.WARNING,
				description: '¿Necesitas ayuda?',
			});
		}
	}, [numbersOfIntents]);

	const showAlertTop = ({ title, type, description }: AlertPayload) => {
		const alert = {
			type,
			title,
			description,
		};
		dispatch(showAlert(alert));
	};

	const [step, setStep] = useState(0);

	return (
		<div>
			{step === 0 && (
				<div className='p-2'>
					<Text
						weight='bold'
						color='medium'
						size='body-1'
						sx='mb-5'
					>
						Vincular mi RFC
					</Text>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='flex flex-col'>
							<Input
								label='RFC'
								control={control}
								name='rfc'
								type='text'
								placeholder='Ingresa tu RFC'
								errorMessage={errors.rfc?.message}
								autofocus
								maxLength={13}
								showValueUpperCase
								showTooltip
								tooltipMessage={msgTooltip}
								sx='mb-4'
							/>

							<Input
								label='Contraseña Plataforma SAT'
								control={control}
								name='password'
								type='password'
								placeholder='Contraseña Plataforma SAT'
								errorMessage={errors.password?.message}
								maxLength={8}
								showTooltip
								tooltipMessage={msgToolTipPassword}
								sx='mb-4'
							/>

							<div className='w-full flex justify-center sm:justify-end'>
								<div className='flex items-center w-full'>
									<div className='w-full flex flex-col-reverse items-center sm:flex-row gap-4 sm:gap-10 sm:ml-auto mt-2 sm:justify-end'>
										<button
											type='button'
											className='flex justify-center'
											onClick={() => setStep(1)}
										>
											<Text
												size='m'
												color='primary'
												sx='text-center justify-center !w-fit'
												weight='medium'
											>
												No tengo contraseña o la olvide
											</Text>
										</button>
										<div className='sm:w-60 w-full'>
											<Button
												loading={isLoading}
												disabled={
													!!errors.rfc || !!errors.password || isLoading
												}
												label={'Vincular mi RFC'}
												type='submit'
											/>
										</div>
									</div>
									{isLoading && pollingStatus === POLLING_STATUS.PENDING && (
										<div className='bg-slate-100 mt-4 p-6'>
											<Text
												size='m'
												color='primary'
											>
												Estamos obteniendo tu información, el proceso puede
												tomar unos segundos...
											</Text>
										</div>
									)}
								</div>
							</div>
						</div>
					</form>
				</div>
			)}
			{step === 1 && (
				<div>
					<div className='absolute left-2 top-2'>
						<CaretCircleLeft
							weight='fill'
							color='var(--h-primary)'
							width={24}
							height={24}
							className='cursor-pointer hidden lg:block'
							onClick={() => setStep(0)}
						/>
					</div>

					<div className='flex flex-col w-full h-[780px] mt-4'>
						<Text
							size='s'
							sx='text-center bg-h-gray-10 flex justify-center py-2 z-10'
							color='medium'
						>
							Aviso: SATID es una página ajena a Heru.
						</Text>
						<iframe
							title='Embedded Page'
							width='100%'
							height='100%'
							src='https://satid.sat.gob.mx/'
							allowFullScreen
						></iframe>
					</div>
				</div>
			)}
		</div>
	);
};

export default SATLink;
